.message-box-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--background-color);
    border-radius: 8px;
    overflow-y: auto;
    align-items: flex-end;
  }
  
  .message-box-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .message-box-container::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
  }
  
  .message-box-container::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  